import Logo from '../assets/Logo.svg';
import HeroImage from '../assets/HeroImage.svg';
import Post1Image from '../assets/posts/post1.jpg';
import PostProfileImage from '../assets/posts/post-profile.svg';
import CtaImage from '../assets/CtaImage.jpg';
const samplePostImage = "/images/sample.jpg";
const userImage = "/images/user.png";

const images = {
    Logo,
    HeroImage,
    Post1Image, 
    PostProfileImage, 
    CtaImage,
    samplePostImage,
    userImage
};

export default images;